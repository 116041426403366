import React, { useState } from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import Sucursal from '../components/Sucursal';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Map from '../components/map';

export default (props) => {
  const sucursales = props.data.allSucursalesJson.nodes;

  const [mainSucursal, setMainSucursal] = useState(sucursales[0]);
  
  return (
    <Layout>

      <PageHeader>
        <h2 className="text-6xl">Sucursales</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/sucursales" className="underline">Sucursales</Link>
        </p>
      </PageHeader>
      
      <Container hideAside={true}>
        <div className="flex flex-col sm:flex-row mb-20 sm:mb-0 mt-4">
          <div className="sm:flex-1">
            <Map lat={mainSucursal.coords.latitude} lng={mainSucursal.coords.longitude} ></Map>
           
          </div>
          <div className="sm:ml-6 mt-4 sm:mt-0" style={{width:"300px"}}>
            <p className="rounded p-2 inline-block text-sm bg-blue-200 text-blue-800 mb-4">Estás viendo:</p>
            <Sucursal sucursal={mainSucursal} main={true}  />
          </div>
        </div>
        <div className="flex flex-wrap justify-around text-center  my-8">
          <div className="bg-white shadow-md w-full md:max-w-lg p-8 bg-gray-100 mt-4">
            <header>
              <h3 className="font-bold text-2xl">Sucursales en Tuxtla Gutiérrez</h3>
              <Link to="/tuxtla-gutierrez" className="d-link mt-4 inline-block">Ver sucursales</Link>
            </header>
          </div>
          <div className="bg-white shadow-md w-full md:max-w-lg p-8 bg-gray-100 mt-4">
            <header>
              <h3 className="font-bold text-2xl">Sucursales Foráneas</h3>
              <Link to="/foraneas" className="d-link mt-4 inline-block">Ver sucursales</Link>
            </header>
          </div>
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    allSucursalesJson(filter: {city: {eq: "Tuxtla Gtz"}},sort:{fields:[order], order:DESC}) {
      nodes {
        name
        address
        coords{
          latitude
          longitude
        }
        horario{
          weekdays{
            starts_at
            ends_at
            starts_at_2
            ends_at_2
          }
          saturday{
            starts_at
            ends_at
          }
          sunday{
            starts_at
            ends_at
          }
        }
      }
    }
    sucursalesJson(name:{eq:"Matriz"}){
    coords{
        latitude
        longitude
      }
    }
  }

`;