import React from 'react';
export default ({ email, className, textPhoneColor, textColor }) => {
  textPhoneColor = textPhoneColor || "d-text-green";
  textColor = textColor || "text-gray-700"
  return (
    <a href={"mailto:" + email} className={textColor + " " + className} style={{ minWidth: "150px" }}>
      <i className={"fa fa-envelope mr-2 " + textPhoneColor}></i>
      Escribir email
    </a>
  );
}