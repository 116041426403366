import React, { Component } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps';
import { compose, withProps } from "recompose";
const mapStyles = {
  width: '100%',
  height: '100%',
};

export default compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAREVvyPTHkWPoBIsNf3jJ6KxwNLswJqwQ",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px`, margin:"0 1rem 0 1rem" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>{
  const {lat, lng} = props;
  return (
    <GoogleMap
      defaultZoom={18}
      center={{ lat, lng }}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  )
});