import React from 'react';
import PhoneNumber from './PhoneNumber';
import Email from './Email';

const Contact = ({ sucursal = {}, noMargin = false } = {})=>(
  
  <div>
    { sucursal.phone &&
      sucursal.phone.map((number,index)=>(
        <p className={"first:mt-0" + (noMargin ? "" : "mt-4")} key={index}><PhoneNumber number={number}  /></p>
      ))
    }
    {
      sucursal.email &&
      <p className={(noMargin ? "" : "mt-4")}> <Email email={sucursal.email} /></p>
    }
  </div>
)


export default (props)=> {
  // const main = props.main || false;
  const sucursal = props.sucursal;
  return (
    <article className={" w-full " + (!props.main && "p-4 sm:w-1/2 md:w-1/3") } key={props.index}>
      <div className={!props.main && " shadow-md"}>
        <header className={"flex flex-col  items-center justify-center " + (!props.main && "p-4 h-48 d-bg-green text-white text-center")}>
          <h4 className={"text-lg font-bold uppercase w-full " + (props.main && "text-2xl") }>{sucursal.name}</h4>
          <address className={"mt-2" + (!props.main && "text-gray-300")}>{sucursal.address}</address>
        </header>
        <footer className={"py-2 text-sm " + (!props.main && " px-4 flex items-center ") + (props.main && " mt-6")}>
          <div className="flex-1">
            <div className="flex-1">
              <p className="uppercase">Lunes - Viernes</p>
              <p className="text-gray-700">{sucursal.horario.weekdays.starts_at}{!sucursal.horario.weekdays.starts_at.includes(":") && ":00"} - {sucursal.horario.weekdays.ends_at}:00</p>
              {
                sucursal.horario.weekdays.starts_at_2 &&
                <p className="mt-0 text-gray-700">{sucursal.horario.weekdays.starts_at_2}:00 - {sucursal.horario.weekdays.ends_at_2}:00 </p>
              }
            </div>
            { sucursal.horario.saturday &&  <div className="flex-1 mt-2">
              <p className="uppercase">Sábado</p>
              <p className="text-gray-700">{sucursal.horario.saturday.starts_at}:00 - {sucursal.horario.saturday.ends_at}:00 </p>
              
            </div>}

            { sucursal.horario.sunday && <div className="flex-1 mt-2">
              <p className="uppercase">Domingo</p>
              <p className="text-gray-700">{sucursal.horario.sunday.starts_at}:00 - {sucursal.horario.sunday.ends_at}:00</p>
            </div>}
          </div>
          {!props.main && sucursal.coords && <div >
            <button className="text-center w-20" onClick={props.view}>
              <img alt="Pincheta de mapa" style={{ height: "1.5rem" }} className="inline-block" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8ZWxsaXBzZSBzdHlsZT0iZmlsbDojMjE5NkYzOyIgY3g9IjI1NiIgY3k9IjQzMiIgcng9IjI0MCIgcnk9IjgwIi8+CjxwYXRoIHN0eWxlPSJmaWxsOiM0NTVBNjQ7IiBkPSJNMjU2LDQ0OGMtOC44MzIsMC0xNi03LjE2OC0xNi0xNlYyMDhjMC04LjgzMiw3LjE2OC0xNiwxNi0xNnMxNiw3LjE2OCwxNiwxNnYyMjQgIEMyNzIsNDQwLjgzMiwyNjQuODMyLDQ0OCwyNTYsNDQ4eiIvPgo8Y2lyY2xlIHN0eWxlPSJmaWxsOiNGNDQzMzY7IiBjeD0iMjU2IiBjeT0iMTEyIiByPSIxMTIiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0VGOUE5QTsiIGQ9Ik0zMDQsMTEyYy04LjgzMiwwLTE2LTcuMTY4LTE2LTE2cy03LjE2OC0xNi0xNi0xNnMtMTYtNy4xNjgtMTYtMTZzNy4xNjgtMTYsMTYtMTYgIGMyNi40NjQsMCw0OCwyMS41MzYsNDgsNDhDMzIwLDEwNC44MzIsMzEyLjgzMiwxMTIsMzA0LDExMnoiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
              <p>Ver en el mapa</p>
            </button>
          </div>}
          {
            !props.main && !sucursal.coords && <Contact sucursal={sucursal} />
          }
          

        </footer>
        <fotter className="text-center" >
          {
            sucursal.coords && (sucursal.email || sucursal.phone) && <div className="block bg-gray-100 p-3"><Contact noMargin={true} sucursal={sucursal} /></div>
          }
        </fotter>
      </div>
    </article>
  )
}